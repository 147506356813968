exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-project-page-tsx-content-file-path-src-projects-open-quadruped-mdx": () => import("./../../../src/templates/ProjectPage.tsx?__contentFilePath=/Users/adham/code/adham-elarabawy.github.io/src/projects/open_quadruped.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-src-projects-open-quadruped-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-src-projects-open-startracker-mdx": () => import("./../../../src/templates/ProjectPage.tsx?__contentFilePath=/Users/adham/code/adham-elarabawy.github.io/src/projects/open_startracker.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-src-projects-open-startracker-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-src-projects-precision-drivetrain-mdx": () => import("./../../../src/templates/ProjectPage.tsx?__contentFilePath=/Users/adham/code/adham-elarabawy.github.io/src/projects/precision_drivetrain.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-src-projects-precision-drivetrain-mdx" */)
}

